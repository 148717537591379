import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Box, TextField, Button } from '@mui/material';
import mba16 from './images/mba16.webp'; // Ensure the image path is correct

const Banner = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    course: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack elements on mobile, side-by-side on larger screens
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: { xs: '20px', md: '0' }, // Add padding on mobile
      }}
    >
      {/* Banner Card */}
      <Card
        sx={{
          width: { xs: '100%', md: '70%' }, // Full width on mobile, 70% on larger screens
          height: { xs: 'auto', md: '100vh' },
          boxShadow: 2,
          marginBottom: { xs: '20px', md: '0' }, // Add margin below on mobile
          position: 'relative',
        }}
      >
        <CardMedia
          component="img"
          image={mba16}
          alt="MBA Program"
          sx={{
            height: { xs: '300px', md: '100%' }, // Fixed height on mobile, full height on larger screens
            objectFit: 'cover',
          }}
        />

        {/* Text and Button aligned horizontally - Left Bottom */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' }, // Stack text and button on mobile, row on larger screens
            alignItems: 'center',
            color: 'white',
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: { xs: '18px', sm: '24px' }, // Smaller font on mobile
            zIndex: 1,
          }}
        >
          {/* Text */}
          <Box sx={{ marginBottom: { xs: '10px', sm: '0' }, marginRight: '10px' }}>
            <h2>Join the Best MBA Colleges</h2>
            <p>Your Future Starts Here</p>
          </Box>

          {/* "Read More" Box beside the text */}
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '20px',
              border: '2px solid #fff',
              cursor: 'pointer',
              marginLeft: { xs: '0', sm: '10px' },
            }}
          >
            <Button
              variant="text"
              sx={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '5px 20px', // Adjust padding for mobile
              }}
            >
              Read More
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Form Card */}
      <Card
        sx={{
          width: { xs: '100%', md: '30%' }, // Full width on mobile, 30% on larger screens
          height: { xs: 'auto', md: '100vh' },
          boxShadow: 3,
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: 'white',
            padding: '20px', // Add padding inside form card
          }}
        >
          <h2 style={{ color: '#333', fontFamily: 'Arial', fontWeight: 'bold', marginBottom: '15px' }}>
            Welcome to Our MBA Program
          </h2>
          <p style={{ color: '#555', fontFamily: 'Arial', fontSize: '16px', marginBottom: '25px' }}>
            Join the best MBA colleges in India
          </p>

          {/* Form with unique design */}
          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '380px', textAlign: 'center' }}>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              color="primary"
              sx={{
                marginBottom: '20px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  backgroundColor: '#f3f4f6',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease-in-out',
                },
                '& .MuiInputLabel-root': {
                  color: '#888',
                },
                '&:hover .MuiOutlinedInput-root': {
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              color="primary"
              sx={{
                marginBottom: '20px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  backgroundColor: '#f3f4f6',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease-in-out',
                },
                '& .MuiInputLabel-root': {
                  color: '#888',
                },
                '&:hover .MuiOutlinedInput-root': {
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
            <TextField
              label="Course"
              name="course"
              value={formData.course}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              color="primary"
              sx={{
                marginBottom: '20px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                  backgroundColor: '#f3f4f6',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease-in-out',
                },
                '& .MuiInputLabel-root': {
                  color: '#888',
                },
                '&:hover .MuiOutlinedInput-root': {
                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="#004080"
              sx={{
                marginTop: '20px',
                marginBottom:'40px',
                fontSize: '16px',
                borderRadius: '50px',
                backgroundColor: 'royalblue',
                '&:hover': {
                  backgroundColor: '#4169e1',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
                width: '70%',
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Banner;
