import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Mba1 from './images/Mba1.jpg';
import mba2 from './images/mba2.jpg';
import mba3 from './images/mba3.jpg';
import mba4 from './images/mba4.jpg';

const CollegeCard = ({ name, location, image, ranking }) => {
  const renderStars = (ranking) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <StarIcon
          key={i}
          sx={{
            color: i < ranking ? 'gold' : 'gray',
            fontSize: 18,
          }}
        />
      );
    }
    return stars;
  };

  return (
    <Card
      sx={{
       width:{md:'300px',xs:'300px'},
        margin: '16px',
        boxShadow: 3,
        backgroundColor: '#66B2FF',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 6,
        },
      }}
    >
      <CardMedia component="img" alt={name} height="140" image={image} />
      <CardContent sx={{ color: 'white', fontWeight: 'bold' }}>
        <Typography gutterBottom variant="h6" component="div">
          {name}
        </Typography>
        <Typography variant="body2" color="white">
          LOCATION: {location}
        </Typography>
        <Typography variant="body2" color="white">
          RANKING: {renderStars(ranking)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Cardcontent = () => {
  const colleges = [
    {
      name: 'Indian School of Business (ISB)',
      location: 'Hyderabad, Telangana',
      image: Mba1,
      ranking: 5,
    },
    {
      name: 'XLRI - Xavier School of Management',
      location: 'Jamshedpur, Jharkhand',
      image: mba2,
      ranking: 4,
    },
    {
      name: 'Faculty of Management Studies (FMS), University of Delhi',
      location: 'Delhi',
      image: mba3,
      ranking: 5,
    },
    {
      name: 'SP Jain Institute of Management and Research (SPJIMR)',
      location: 'Mumbai, Maharashtra',
      image: mba4,
      ranking: 4,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack the cards and the button vertically
        alignItems: 'center', // Center the content horizontally
        padding: '20px', // Add some padding
      }}
    >
      {/* Render the College Cards in a straight line */}
      <Box
      md={12}
        sx={{
          display: 'flex',
          gap: '16px', // Space between cards
          flexWrap: 'wrap', // Allow wrapping on smaller screens
          justifyContent: { xs: 'center',md:'flex-start' }, // Adjust alignment on smaller screens
        }}
      >
        {colleges.map((college, index) => (
          <CollegeCard 
          key={index} {...college} />
        ))}
      </Box>

      {/* Explore More Button */}
      <Button
        variant="contained"
        component="a" // Render the button as an anchor
        href="https://www.getmycollege.com/" // URL to navigate
        target="_blank" // Open link in a new tab
        rel="noopener noreferrer" // Prevent security vulnerabilities
        sx={{
          marginTop: '20px',
          backgroundColor: '#004080',
          color: 'white',
          '&:hover': {
            backgroundColor: '#6666FF',
          },
          borderRadius: '50px', // Make button rounded
          padding: '10px 30px',
          fontSize: { xs: '12px', sm: '16px' }, // Adjust button font size for mobile
        }}
      >
        Explore More
      </Button>
    </Box>
  );
};

export default Cardcontent;
