import React from 'react';
import { CardMedia, Box, Typography, Divider, Button, Grid } from '@mui/material';
import mba19 from './images/mba19.jpg'; // Ensure this path points to your image

const Chooseus = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on smaller screens, horizontally on larger ones
        justifyContent: 'center',
        alignItems: 'stretch',
        height: '100vh',
        gap: 2, // Add spacing between the cards
        padding: { xs: '20px', md: '40px' }, // Add padding for smaller screens
      }}
    >
      {/* Text Content without Card */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: { xs: '20px', md: '30px' }, // Adjust padding for mobile
        }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#004080',
              marginBottom: '5px',
              mt: { xs: 9, md: 0, sm: 2 },
            }}
          >
            Why Choose Us?
          </Typography>

          {/* Stylish Divider */}
          <Divider
            sx={{
              backgroundColor: '#FFC107',
              height: '2px',
              width: '50%',
              borderRadius: '2px',
            }}
          />

          <Typography variant="body1" sx={{ fontSize: '16px', color: '#333', lineHeight: '1.6' }}>
            Our MBA program stands out with a unique blend of academic excellence, industry exposure, and personal growth opportunities.
            Choose us to:
          </Typography>
          <ul style={{ color: '#333', fontSize: '16px', lineHeight: '1.8' }}>
            <li>Learn from globally renowned faculty members.</li>
            <li>Network with industry leaders and peers.</li>
            <li>Access state-of-the-art facilities and resources.</li>
            <li>Participate in global exchange programs and internships.</li>
          </ul>

          <Typography variant="body1" sx={{ fontSize: '16px', color: '#555' }}>
            With a focus on innovation, leadership, and sustainability, we prepare you to excel in today’s competitive business environment.
          </Typography>

          {/* Know More Button */}
          <Box
            sx={{
              textAlign: { xs: 'center', md: 'left' }, // Center on mobile, left on larger screens
              marginTop: '20px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              href="https://www.getmycollege.com/" // Link added here
              target="_blank" // Open link in a new tab
              rel="noopener noreferrer" // For security
              sx={{
                backgroundColor: '#004080',
                '&:hover': {
                  backgroundColor: '#003366',
                },
                fontSize: '16px',
                borderRadius: '5px',
              }}
            >
              Know More
            </Button>
          </Box>
        </Grid>
      </Box>

      <Grid item xs={12} md={6}>
        {/* Right Card with Image */}
        <CardMedia
          component="img"
          image={mba19}
          alt="Why Choose Us"
          sx={{
            flex: 1,
            boxShadow: 4,
            borderRadius: '15px',
            overflow: 'hidden',
            height: '80%',
            objectFit: 'cover',
          }}
        />
      </Grid>
    </Box>
  );
};

export default Chooseus;
