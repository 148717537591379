import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cardcontent from "./components/Cardcontent";
import Banner from "./components/Banner";
import Mba from "./components/Mba";
import AutoPopup from "./components/AutoPopUp";
import Chooseus from "./components/Chooseus";

function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
  });

  return (
    <div>
      <Header />
      <Banner />
      <Cardcontent />
      <Mba />
      <Chooseus/>
      <Footer />
      {/* <AutoPopup
        isOpen={isAutoPopupOpen}
        onClose={handleCloseAutoPopup}
        leadFlag="Organic"
      /> */}
    </div>
  );
}

export default App;
