import React from 'react';
import { Card, CardContent, CardMedia, Box, Typography, Divider } from '@mui/material';
import mba18 from './images/mba18.jpg';

const Mba = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile, horizontally on larger screens
        gap: 2, // Add spacing between cards
      }}
    >
      <Card
        sx={{
          width: { xs: '80%', sm: '45%' }, // Adjust width for smaller screens
          height: { xs: '40%', sm: '70%' }, // Adjust height for smaller screens
          boxShadow: 3,
          display: 'flex',
          marginBottom: { xs: '20px', sm: '0' }, // Add margin at the bottom on small screens
        }}
      >
        <CardMedia
          component="img"
          image={mba18}
          alt="MBA Colleges"
          sx={{
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </Card>

      <Card
        sx={{
          width: { xs: '80%', sm: '45%' }, // Adjust width for smaller screens
          height: { xs: 'auto', sm: '70%' }, // Adjust height for smaller screens
          boxShadow: 3,
        }}
      >
        <CardContent sx={{ padding: 2 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Explore Top MBA Colleges
          </Typography>

          <Divider sx={{ marginBottom: '20px', backgroundColor: '#B4B8B1', height: '2px' }} />

          <Typography variant="body1" sx={{ fontSize: '18px', color: '#555' }}>
            Our MBA programs offer world-class education designed to equip you with the skills necessary for a successful career in business. 
            Join one of the most prestigious MBA colleges and take the first step towards becoming a future leader.
          </Typography>

          <Typography variant="body1" sx={{ fontSize: '18px', color: '#555', marginTop: '20px' }}>
            Learn from expert professors, network with industry professionals, and gain practical experience through internships and projects.
            Our MBA programs are designed to challenge you and help you grow both personally and professionally.
          </Typography>

          <Typography variant="body1" sx={{ fontSize: '18px', color: '#555', marginTop: '20px' }}>
            With state-of-the-art infrastructure, a global curriculum, and industry partnerships, our MBA programs are a gateway to a successful career.
            Apply now to secure your place in one of India's leading business schools.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Mba;
