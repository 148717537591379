import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#1D3557',
        color: 'white',
        borderTop: '1px solid #ddd',
        mt: 'auto',
        padding: '10px 20px',
      }}
    >
      <Grid 
        container 
        spacing={2} 
        justifyContent="center" 
        alignItems="center"
        sx={{ textAlign: { xs: 'center', sm: 'left' } }}
      >
        {/* Logo or Title */}
        <Grid item xs={12} sm={6} textAlign="center">
          <Typography 
            variant="body2" 
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              padding: '5px',
            }}
          >
            Copyright © {new Date().getFullYear()} -{' '}
            <span style={{ fontWeight: 'bold' }}>TopMBAColleges.com</span>. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
