import React from 'react';
import { AppBar, Toolbar, Typography, Box, Grid, Button, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import logo from './images/logo.png';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen width is <= 600px
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Home', href: 'https://www.getmycollege.com/' },
    { text: 'About Us', href: 'https://www.getmycollege.com/' },
    { text: 'Top MBA Colleges', href: 'https://www.getmycollege.com/' },
    { text: 'Contact Us', href: 'https://www.getmycollege.com/' },
  ];

  return (
    <AppBar position="static" sx={{ backgroundColor: '#1D3557', height: '80px' }}>
      <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* Left Section: Logo and Title */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={logo}
            alt="College Logo"
            style={isMobile ? { height: '60px', width: '65px', borderRadius: '90px' } : { height: '72px', width: '78px', borderRadius: '90px' }}
          />
          <Typography
            variant="h6"
            sx={{
              color: 'white',
              marginLeft: '10px',
              fontWeight: 'bold',
              fontSize: isMobile ? '16px' : '20px',
            }}
          >
            MBA Colleges in India
          </Typography>
        </Box>

        {/* Right Section: Navigation */}
        {isMobile ? (
          // Mobile: Drawer for Navigation
          <>
            <IconButton
              color="inherit"
              onClick={toggleDrawer(true)}
              sx={{
                marginRight: '8px', // Add spacing on the right
              }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              sx={{ width: '250px' }}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {menuItems.map((item, index) => (
                    <ListItem button key={index} component="a" href={item.href} target="_blank" rel="noopener noreferrer">
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          // Desktop: Navigation Buttons
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Grid container spacing={2}>
              {menuItems.map((item, index) => (
                <Grid item key={index}>
                  <Button
                    variant="text"
                    sx={{
                      color: 'white',
                      '&:hover': { color: 'white' },
                      padding: '8px 16px',
                      fontSize: '14px',
                      height: '40px',
                    }}
                    component="a"
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.text}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
